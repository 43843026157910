import * as React from "react";
import B from "../../assets/icons/b.svg";
import { Header as HeaderComponent } from "./styles";

const Header = () => {
  return (
    <HeaderComponent>
      <B width="24" />
    </HeaderComponent>
  );
};

export default Header;
