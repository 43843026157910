import React from "react";
import {
  Container,
  InfoContainer,
  DateContainer,
  Title,
  SubTitle,
  DetailContainer,
  Detail,
} from "./styles";

const Card = ({ data }) => {
  return (
    <Container>
      <InfoContainer>
        <DateContainer>{data?.date ?? ""}</DateContainer>
        <Title>{data?.title ?? ""}</Title>
        <SubTitle>{data?.sub_title ?? ""}</SubTitle>
      </InfoContainer>
      <DetailContainer>
        {data?.details?.map((detail, key) => (
          <Detail key={key}>{detail ?? ""}</Detail>
        ))}
      </DetailContainer>
    </Container>
  );
};

export default Card;
